import { win, doc, conf } from "./globals.js";

const cssChunk = name =>
  doc.head.append(
    mk("link", { rel: "stylesheet", href: `/.md.lib/chunks/${name}.css` }));

const chunks = {
  code_copy: () => {
    cssChunk("code_copy");
    win.addEventListener("click", ({ target, offsetX }) => {
      if (target.nodeName !== "PRE") return;
      if (offsetX <= target.offsetWidth) return;
      navigator.clipboard.writeText(target.textContent);
      const style = target.style;
      Object.assign(style, { backgroundColor: "#88c8" });
      setTimeout(() =>
        Object.assign(style, { transition: "background-color 300ms", backgroundColor: "" }),
        500);
      setTimeout(() =>
        Object.assign(style, { transition: "" }),
        800);
    });
  },

  toc: () => {
    cssChunk("toc");
    win.addEventListener("md-rendered", () => {
      if (win.innerHeight >= doc.body.scrollHeight) return;
      const headers = doc.querySelectorAll("h1,h2");
      if (headers.length < 3) return;
      const toc = mk("div", { id: "toc" });
      for (const h of doc.querySelectorAll("h1,h2")) {
        const a = mk("a", { classList: h.tagName.toLowerCase() });
        a.innerHTML = h.innerHTML;
        const st = h.style;
        a.addEventListener("click", ()=> {
          st.scrollMarginTop = "1ex"; st.transition = "all 300ms";
          st.color = "black"; st.backgroundColor = "white";
          setTimeout(()=> {
            st.scrollMarginTop = ""; st.color = ""; st.backgroundColor = "";
            setTimeout(()=> st.transition = "", 500);
          }, 1000);
          h.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        toc.append(a, mk("br"));
      }
      const w = doc.querySelector("#widgets");
      if (w) w.append(mk("div", { className: "flex-break" }), toc);
      else doc.querySelector(".md-rendered").prepend(toc);
    });
  },

  mermaid: async () => {
    const { default: mermaid } =
      await import("https://cdn.jsdelivr.net/npm/mermaid@10/dist/mermaid.esm.min.mjs");
    mermaid.initialize({
      startOnLoad: false,
      theme: "dark",
      securityLevel: "loose",
    });
    cssChunk("mermaid");
    doc.querySelectorAll("pre > code.language-mermaid").forEach(async (elt, i) => {
      try {
        const { svg, bindFunctions } = await mermaid.render("mermaid-"+i, elt.textContent);
        elt.outerHTML = svg; bindFunctions?.(elt);
      } catch (e) { console.error("Rendering error: "+e?.message || e); }
    });
  },

  // standard mathjax use
  mathjax: async () => {
    const base = "https://cdn.jsdelivr.net/npm/mathjax@3/es5";
    win.MathJax = {
      options: { enableMenu: false },
      tex: {
        inlineMath:  [["$",  "$" ], ["\\(", "\\)"]],
        displayMath: [["$$", "$$"], ["\\[", "\\]"]],
      },
      chtml: { fontURL: base + "/output/chtml/fonts/woff-v2" },
    };
    await import(base + "/tex-chtml.js");
  },

  // mml rendering: much smaller footprint
  //   (from https://docs.mathjax.org/en/latest/output/mathml.html)
  mathjax_mml: async () => {
    const base = "https://cdn.jsdelivr.net/npm/mathjax@3/es5";
    win.MathJax = {
      loader: {load: ["input/tex"],
               paths: { mathjax: base }},
      tex: {
        inlineMath:  [["$",  "$" ], ["\\(", "\\)"]],
        displayMath: [["$$", "$$"], ["\\[", "\\]"]],
      },
      startup: {
        pageReady() {
          win.MathJax._.mathjax.mathjax.handleRetriesFor(() => win.MathJax.startup.document.render());
        }
      },
      options: {
        renderActions: {
          typeset: [150,
                    (doc) => [...doc.math].forEach(math => win.MathJax.config.renderMathML(math, doc)),
                    (math, doc) => win.MathJax.config.renderMathML(math, doc)
                   ]
        },
      },
      renderMathML(math, doc) {
        math.typesetRoot = doc.createElement("mjx-container");
        math.typesetRoot.innerHTML = win.MathJax.startup.toMML(math.root);
        math.display && math.typesetRoot.setAttribute("display", 'block');
      }
    };
    cssChunk("mathjax_mml");
    await import(base + "/startup.js");
  },

};

const chunksDone = [];

const doChunk = name =>
  chunksDone.includes(name) ? ""
  : !(name in chunks) ? `[[[:error:\nUnknown chunk: \`${name}\`\n]]]\n\n`
  : (chunks[name](), chunksDone.push(name), "");

export const eliChunks = s => {
  const use = s.match(/^\s*<use:([\w, ]+)>(?: *\n)*/);
  return [...conf?.defaultChunks ?? [],
          ...use?.[1].trim().split(/ *, */g) ?? []
         ].map(doChunk).join("")
         + s.slice(use?.[0].length ?? 0);
};
