const defaultConf = {
  widgets: null, // null or a list of widgets: "updater", "as-text"
  defaultChunks: null, // null or list of default chunk names (from `eli-chunks.js`)
  parentElt: "body", // selector for md container
  links: "full", // full-links open in new tabs, true to make all do that, null for none
};

// true in a real browser -- not jsdom (grab before we set the global window)
export const isBrowser = typeof globalThis.window !== "undefined";

globalThis.conf = { ...defaultConf, ...(globalThis.conf ?? {}) };

import { JSDOM } from "#env";
if (JSDOM) {
  // allow setting this before running if needed
  globalThis.theDom ??=
    new JSDOM("<!doctype html><html><body></body><html>",
              { url: "https://localhost/" });
  // make some indirect toplevel values, so there's always browser-like globals
  ["window", "document", "localStorage"].forEach(name =>
    Object.defineProperty(globalThis, name, {
      get: () => globalThis.theDom.window[name],
      set: v => { throw Error("cannot set the ${name} global", name); },
      enumerable: true,
    }));
}

// short lexically scoped names (maybe use the globals instead?)
export const { conf, window: win, document: doc } = globalThis;
