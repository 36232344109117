// Markdown rendering

import mdIt from "markdown-it";
import mdItDeflist from "markdown-it-deflist";
import mdItFootnote from "markdown-it-footnote";
import { evals } from "./evals.js";
import { eliLists } from "./eli-lists.js";
import { eliChunks } from "./eli-chunks.js";
import { eliInlines } from "./eli-inlines.js";
import { eliContainers, xformDetails } from "./eli-containers.js";
import { xformLinkTargets } from "./eli-links.js";
import { eliReplacements, xformEmojis } from "./eli-replacements.js";
import { highlight } from "#env";
import { X, Xs, _trimLines, replaceOpenClose } from "./pipe.js";
import { mk } from "./mk.js";
import { addReset } from "./utils.js";

const miOpts = { html: true, linkify: true, typographer: true, highlight };
const miRenderer = mdIt(miOpts)
  .disable("list").use(eliLists)
  .disable("replacements").use(eliReplacements)
  .use(eliContainers("[", "]"))
  .disable("strikethrough")
  .use(eliInlines("~~", "s"))
  .use(eliInlines("++", "ins"))
  .use(eliInlines("==", "mark"))
  .use(mdItDeflist)
  .use(mdItFootnote);

export const mdRender       = str => miRenderer.render(str);
export const mdRenderInline = str => miRenderer.renderInline(str);

// ----------------------------------------------------------------------------
// Comments

// Use [[[#...]]] to make it like [[[...]]] blocks
export const commenter = replaceOpenClose([
  /(?<![\[\\])\[\[\[+(?=#)/, /\]\]\]+(?!\])/, (a, b) => a[0].length === b[0].length],
  s => "");

// ----------------------------------------------------------------------------

const toDom = (x, state) => {
  if (state.elt) {
    state.elt.innerHTML = x;
    return state.elt;
  } else {
    const d = mk("div");
    d.innerHTML = x;
    return d;
  }
};
const fromDom = (x, state) =>
  state.elt ? null // got an elt to render into, no need for a return value
  : x.innerHTML;

const preExtras = [], postExtras = [];
addReset(() => preExtras.length = postExtras.length = 0);
export const addPre  = f => (preExtras .push(f), undefined);
export const addPost = f => (postExtras.push(f), undefined);

// ----------------------------------------------------------------------------
const mkRender = (inline = false) => X(
  _trimLines,
  evals,
  commenter,
  (x, state) => { state.getAsText?.(x); return x; }, // -----------------------
  eliChunks,
  Xs(preExtras),
  inline ? mdRenderInline : mdRender,
  toDom,
  xformDetails,
  Xs(postExtras),
  xformLinkTargets,
  xformEmojis,
  fromDom,
); // -------------------------------------------------------------------------

export const render       = mkRender();
export const renderInline = mkRender(true);
