import * as utils from "./utils.js";
import * as mk from "./mk.js";
import * as md from "./md.js";
import * as pipe from "./pipe.js";
import * as evals from "./evals.js";

import { isBrowser } from "./globals.js";
if (isBrowser) import("./main.js");

// Make exports available in the global namespace
[utils, mk, md, pipe, evals]
  .forEach(mod => Object.entries(mod).forEach(([id, val]) =>
    globalThis[id] ??= val));
