// Convenient element creation (with possibly nested attributes)

import { win, doc } from "./globals.js";
import { _q } from "./utils.js";

export const mk = (tag, ...elts) => {
  let find = false;
  if (tag === mkOrGet) find = true, [tag, ...elts] = elts;
  const attrs = elts.length && isPlainObject(elts[0]) && elts.shift();
  if (find) {
    if (!attrs.id) console.error(`mkOrGet() used without an "id" attribute`);
    else if (find = _q("#"+attrs.id)) return find;
  }
  const elt = doc.createElement(tag);
  const aloop = (o, as) =>
    Object.entries(as).forEach(([a, v]) =>
      isPlainObject(v) ? aloop(o[a] ??= {}, v) : o[a] = v);
  if (attrs) aloop(elt, attrs);
  const loop = e => e && (
    e instanceof win.Node ? elt.appendChild(e)
    : Array.isArray(e) ? e.forEach(loop)
    : typeof e !== "string" && e?.[Symbol.iterator] ? loop(Array.from(e))
    : loop(doc.createTextNode(e))
  );
  elts.forEach(loop);
  return elt;
};

// try to get an existing element by id, create if none
export const mkOrGet = (...xs) => mk(mkOrGet, ...xs);

const isPlainObject = x => {
  if (typeof x !== "object" || x === null) return false;
  const prototype = Object.getPrototypeOf(x);
  return prototype === Object.prototype || prototype === null;
};
