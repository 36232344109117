import { doc } from "./globals.js";

export const __  = id => doc.getElementById(id);
export const _q  = s  => doc.querySelector(s);
export const _qa = s  => doc.querySelectorAll(s);

const allResets = [];
export const addReset = f => allResets.push(f);
export const doResets = () => allResets.forEach(r => r());

export const rxQuote = s => s.replace(/[.*+?^${}()|\[\]\\]/g, "\\$&");

export const rxGroupsLength = rx =>
  RegExp(rx.source + "|").exec("").length - 1;

export const allGapsAndMatches = (s, rx) => {
  let i = 0;
  const r = [];
  for (const m of s.matchAll(rx)) {
    r.push(s.slice(i, m.index), m);
    i = m.index + m[0].length;
  }
  r.push(s.slice(i));
  return r;
};

export const combineStrings = xs => {
  const r = [];
  for (const x of xs) {
    if (x === "") continue;
    if (typeof x !== "string" || typeof r.at(-1) !== "string") r.push(x);
    else r[r.length-1] += x;
  }
  return r;
};

export const replaceInTexts = (rx, replacer) => elt => {
  let e = elt.firstChild;
  const next = e => e === elt ? null : e.nextSibling ?? next(e.parentNode);
  const replace = e => {
    const { textContent } = e;
    const xs = combineStrings(
      allGapsAndMatches(textContent, rx)
        .map(m => typeof m === "string" ? m : replacer(m)))
      .map(x => typeof x !== "string" ? x : doc.createTextNode(x));
    if (xs.length === 0) return e;
    e.after(...xs); e.remove();
    return xs.at(-1);
  };
  while (e)
    e = e.nodeName === "#text" ? next(replace(e))
      : e.nodeName === "CODE" ? next(e)
      : e.firstChild ?? next(e);
  return elt;
};

export const dedent = s => {
  const n = [...("\n"+s).matchAll(/\n *[^\s]/g)]
    .reduce((n,m) => Math.min(n, m[0].length), s.length) - 2;
  return !n ? s : s.replaceAll(RegExp(`^ {0,${n}}`, "mg"), "");
};

export const parentList = (elt, upto = doc.documentElement) => {
  const r = [elt];
  while (elt && elt !== upto) r.push(elt = elt.parentElement);
  return r;
};

export const kosherizeScripts = node =>
  node.tagName === "SCRIPT" ? cloneScript(node)
  : node.childNodes.forEach(kosherizeScripts);
const cloneScript = node => {
  const script = doc.createElement("script");
  script.text = node.textContent;
  [...node.attributes].forEach(a => script.setAttribute(a.name, a.value));
  node.parentNode.replaceChild(script, node);
};
